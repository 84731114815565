import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"
import Data from "../data/data"
import style from "./contact.module.css"

class ThanksPage extends React.Component {
  render() {
    return (
      <>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{Data.contact.title}</title>
            <meta
              name="description"
              content={Data.metaData.description.contact}
            />
          </Helmet>

          {/* Banner */}
          <div className={style.root}>
            <div className={style.contact__banner}>
              <div className="container">
                <div className={`row ${style.customRow}`}>
                  <div className="col s12">
                    <div className={style.contact__bannerContent}>
                      <h1 className={style.contact__heading}>
                        {Data.contact.heading}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Banner Image */}
          <div className={style.contact__image}></div>

          {/* Form Section */}
          <div className={style.form}>
            <div className="container">
              <div className={`row ${style.customRow}`}>
                <div className="col s12">
                  <div className={style.form__element} style={{ height: 'initial' }}>
                    <div className={style.form__Content}>
                      <h4 className={style.form__heading}>
                        Thanks for getting in touch. We'll get back to you as soon as we can.
                      </h4>
                      <div className="row">
                        <div className="col s12">
                          <h4 className={style.form__headingDesktop}>
                            Thanks for getting in touch. We'll get back to you as soon as we can.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

ThanksPage.propTypes = {}

export default ThanksPage
